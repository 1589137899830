@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto+Mono:wght@300&family=Roboto+Slab:wght@300&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

:root {
    --blue: #068FFF;
    --white: #d3d3d3;
    --purple: #4949da;
    --black: #000000
}

#main {
    @apply md:ml-[41.66%] lg:ml-[20%] px-8 lg:px-40 xl:px-60 2xl:px-96 md:pt-20;
    font-family: "Nunito Sans", sans-serif;
}
