.img {
    @apply rounded-2xl p-2 mb-8
}

.subtitle {
    @apply text-lg text-[var(--purple)] font-bold
}

.marked {
    @apply font-bold text-[var(--purple)]
}

.link {
    @apply font-bold underline
}

.list {
    @apply list-disc list-inside marker:text-[var(--purple)]
}

.imageref {
    @apply text-gray-500 text-center
}
