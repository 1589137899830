.nav{
    @apply
    bg-[var(--purple)]
    mx-auto
    px-3 
    py-2
    sticky
    top-0 
    z-10 
    h-max
}
.navList{
    @apply
    my-2 
    flex 
    flex-col 
    gap-2 
    md:mb-0 
    md:mt-0 
    md:flex-row 
    md:items-center 
    items-center
    md:gap-6
}
.navItem
{
    @apply
    text-lg
    text-white;
    font-family: 'Roboto Slab', serif;
}
.container{
    @apply
    max-w-screen-xl
    flex
    flex-wrap 
    items-center 
    justify-between 
    mx-auto
    p-1
}
.name{
    @apply
    text-2xl
    text-white
    font-bold
    text-center;
    font-family: 'Playfair Display', serif;
}
.firstName{
    @apply
    text-[var(--black)]
}
.collapseBTN{
    @apply
    ml-auto 
    h-6 
    w-6 
    text-inherit 
    hover:bg-transparent 
    focus:bg-transparent 
    active:bg-transparent 
    md:hidden
} 
.collapse{
    @apply
    block
    md:hidden
    w-full
    rounded-lg
    bg-black; 
}
.navFull{
    @apply
    hidden
    md:block
}
.mainItems{
    @apply
    block
    inline-flex
    items-center
}
.mainLinks{
    @apply
    hidden
    md:flex
    inline-flex
    items-center
    gap-5
    pl-4
}
.links{
    @apply
    md:hidden
    flex
    inline-flex
    gap-4
}
.logoGH
{
    @apply
    md:h-7
    md:w-7
    h-10
    w-10;
       
}
.logoIN{
    @apply
    md:h-[2rem]
    md:w-[2rem]
    h-[3rem]
    w-[3rem];
}
.floating{
    @apply
    fixed
    right-2
    bottom-2
}
.containerSwitch{
    @apply
    h-[3rem]
    w-[10rem]
    rounded-full
    flex 
    items-center
    justify-center
    drop-shadow-2xl
    bg-[var(--purple)]
}

.switchLabel{
    @apply
    relative 
    inline-flex 
    cursor-pointer 
    select-none 
    items-center
}
.translator{
    @apply
    md:pl-[2rem]
    sm:pl-0
}
.switchColor{
    @apply 
    mx-4 
    flex 
    h-6
    w-[3.5rem] 
    items-center 
    rounded-full
    bg-white
    p-1 
    duration-200
}
.switchDot{
    @apply
    h-5
    w-5 
    rounded-full 
    bg-[var(--purple)]
    duration-200
}
