.header_mobile {
    @apply bg-[var(--purple)] pb-6 font-bold text-3xl w-screen -mx-8 px-8 text-white;
}

.title {
    /* global */
    @apply text-5xl font-bold text-[var(--purple)];
    /* sm */
}

.dots {
    @apply text-5xl text-[var(--purple)] text-center
}

.content_container {
    @apply pt-5 pb-20 text-justify text-gray-600
}

.icon {
    @apply h-10 w-10 md:h-9 md:w-9;
}

.links {
    @apply flex md:flex-col text-sm md:text-base text-[var(--purple)] justify-center space-x-4 space-y-2 md:space-x-0 mt-2 font-bold;
}

.link {
    @apply inline-flex items-center space-x-3;
}
